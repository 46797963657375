exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-en-jsx": () => import("./../../../src/pages/about/index.en.jsx" /* webpackChunkName: "component---src-pages-about-index-en-jsx" */),
  "component---src-pages-about-index-et-jsx": () => import("./../../../src/pages/about/index.et.jsx" /* webpackChunkName: "component---src-pages-about-index-et-jsx" */),
  "component---src-pages-about-index-lv-jsx": () => import("./../../../src/pages/about/index.lv.jsx" /* webpackChunkName: "component---src-pages-about-index-lv-jsx" */),
  "component---src-pages-about-index-ru-jsx": () => import("./../../../src/pages/about/index.ru.jsx" /* webpackChunkName: "component---src-pages-about-index-ru-jsx" */),
  "component---src-pages-features-index-en-jsx": () => import("./../../../src/pages/features/index.en.jsx" /* webpackChunkName: "component---src-pages-features-index-en-jsx" */),
  "component---src-pages-features-index-et-jsx": () => import("./../../../src/pages/features/index.et.jsx" /* webpackChunkName: "component---src-pages-features-index-et-jsx" */),
  "component---src-pages-features-index-lv-jsx": () => import("./../../../src/pages/features/index.lv.jsx" /* webpackChunkName: "component---src-pages-features-index-lv-jsx" */),
  "component---src-pages-features-index-ru-jsx": () => import("./../../../src/pages/features/index.ru.jsx" /* webpackChunkName: "component---src-pages-features-index-ru-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-et-jsx": () => import("./../../../src/pages/index.et.jsx" /* webpackChunkName: "component---src-pages-index-et-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-lv-jsx": () => import("./../../../src/pages/index.lv.jsx" /* webpackChunkName: "component---src-pages-index-lv-jsx" */),
  "component---src-pages-index-ru-jsx": () => import("./../../../src/pages/index.ru.jsx" /* webpackChunkName: "component---src-pages-index-ru-jsx" */),
  "component---src-pages-policy-index-jsx": () => import("./../../../src/pages/policy/index.jsx" /* webpackChunkName: "component---src-pages-policy-index-jsx" */),
  "component---src-pages-prices-index-en-jsx": () => import("./../../../src/pages/prices/index.en.jsx" /* webpackChunkName: "component---src-pages-prices-index-en-jsx" */),
  "component---src-pages-prices-index-et-jsx": () => import("./../../../src/pages/prices/index.et.jsx" /* webpackChunkName: "component---src-pages-prices-index-et-jsx" */),
  "component---src-pages-prices-index-lv-jsx": () => import("./../../../src/pages/prices/index.lv.jsx" /* webpackChunkName: "component---src-pages-prices-index-lv-jsx" */),
  "component---src-pages-solutions-industrial-access-control-index-en-jsx": () => import("./../../../src/pages/solutions/industrial/access-control/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-access-control-index-en-jsx" */),
  "component---src-pages-solutions-industrial-access-control-index-et-jsx": () => import("./../../../src/pages/solutions/industrial/access-control/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-access-control-index-et-jsx" */),
  "component---src-pages-solutions-industrial-access-control-index-lv-jsx": () => import("./../../../src/pages/solutions/industrial/access-control/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-access-control-index-lv-jsx" */),
  "component---src-pages-solutions-industrial-energy-distribution-index-en-jsx": () => import("./../../../src/pages/solutions/industrial/energy-distribution/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-energy-distribution-index-en-jsx" */),
  "component---src-pages-solutions-industrial-energy-distribution-index-et-jsx": () => import("./../../../src/pages/solutions/industrial/energy-distribution/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-energy-distribution-index-et-jsx" */),
  "component---src-pages-solutions-industrial-energy-distribution-index-lv-jsx": () => import("./../../../src/pages/solutions/industrial/energy-distribution/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-energy-distribution-index-lv-jsx" */),
  "component---src-pages-solutions-industrial-environment-monitoring-index-en-jsx": () => import("./../../../src/pages/solutions/industrial/environment-monitoring/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-environment-monitoring-index-en-jsx" */),
  "component---src-pages-solutions-industrial-environment-monitoring-index-et-jsx": () => import("./../../../src/pages/solutions/industrial/environment-monitoring/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-environment-monitoring-index-et-jsx" */),
  "component---src-pages-solutions-industrial-environment-monitoring-index-lv-jsx": () => import("./../../../src/pages/solutions/industrial/environment-monitoring/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-environment-monitoring-index-lv-jsx" */),
  "component---src-pages-solutions-industrial-index-en-jsx": () => import("./../../../src/pages/solutions/industrial/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-index-en-jsx" */),
  "component---src-pages-solutions-industrial-index-et-jsx": () => import("./../../../src/pages/solutions/industrial/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-index-et-jsx" */),
  "component---src-pages-solutions-industrial-index-lv-jsx": () => import("./../../../src/pages/solutions/industrial/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-industrial-index-lv-jsx" */),
  "component---src-pages-solutions-residential-access-control-index-en-jsx": () => import("./../../../src/pages/solutions/residential/access-control/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-access-control-index-en-jsx" */),
  "component---src-pages-solutions-residential-access-control-index-et-jsx": () => import("./../../../src/pages/solutions/residential/access-control/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-access-control-index-et-jsx" */),
  "component---src-pages-solutions-residential-access-control-index-lv-jsx": () => import("./../../../src/pages/solutions/residential/access-control/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-access-control-index-lv-jsx" */),
  "component---src-pages-solutions-residential-environment-index-en-jsx": () => import("./../../../src/pages/solutions/residential/environment/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-environment-index-en-jsx" */),
  "component---src-pages-solutions-residential-environment-index-et-jsx": () => import("./../../../src/pages/solutions/residential/environment/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-environment-index-et-jsx" */),
  "component---src-pages-solutions-residential-environment-index-lv-jsx": () => import("./../../../src/pages/solutions/residential/environment/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-environment-index-lv-jsx" */),
  "component---src-pages-solutions-residential-index-en-jsx": () => import("./../../../src/pages/solutions/residential/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-index-en-jsx" */),
  "component---src-pages-solutions-residential-index-et-jsx": () => import("./../../../src/pages/solutions/residential/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-index-et-jsx" */),
  "component---src-pages-solutions-residential-index-lv-jsx": () => import("./../../../src/pages/solutions/residential/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-index-lv-jsx" */),
  "component---src-pages-solutions-residential-service-desk-index-en-jsx": () => import("./../../../src/pages/solutions/residential/service-desk/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-service-desk-index-en-jsx" */),
  "component---src-pages-solutions-residential-service-desk-index-et-jsx": () => import("./../../../src/pages/solutions/residential/service-desk/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-service-desk-index-et-jsx" */),
  "component---src-pages-solutions-residential-service-desk-index-lv-jsx": () => import("./../../../src/pages/solutions/residential/service-desk/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-service-desk-index-lv-jsx" */),
  "component---src-pages-solutions-residential-smart-energy-index-en-jsx": () => import("./../../../src/pages/solutions/residential/smart-energy/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-smart-energy-index-en-jsx" */),
  "component---src-pages-solutions-residential-smart-energy-index-et-jsx": () => import("./../../../src/pages/solutions/residential/smart-energy/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-smart-energy-index-et-jsx" */),
  "component---src-pages-solutions-residential-smart-energy-index-lv-jsx": () => import("./../../../src/pages/solutions/residential/smart-energy/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-smart-energy-index-lv-jsx" */),
  "component---src-pages-solutions-residential-smart-metering-index-en-jsx": () => import("./../../../src/pages/solutions/residential/smart-metering/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-smart-metering-index-en-jsx" */),
  "component---src-pages-solutions-residential-smart-metering-index-et-jsx": () => import("./../../../src/pages/solutions/residential/smart-metering/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-smart-metering-index-et-jsx" */),
  "component---src-pages-solutions-residential-smart-metering-index-lv-jsx": () => import("./../../../src/pages/solutions/residential/smart-metering/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-smart-metering-index-lv-jsx" */),
  "component---src-pages-solutions-residential-smart-metering-index-ru-jsx": () => import("./../../../src/pages/solutions/residential/smart-metering/index.ru.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-smart-metering-index-ru-jsx" */),
  "component---src-pages-solutions-residential-water-level-index-en-jsx": () => import("./../../../src/pages/solutions/residential/water-level/index.en.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-water-level-index-en-jsx" */),
  "component---src-pages-solutions-residential-water-level-index-et-jsx": () => import("./../../../src/pages/solutions/residential/water-level/index.et.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-water-level-index-et-jsx" */),
  "component---src-pages-solutions-residential-water-level-index-lv-jsx": () => import("./../../../src/pages/solutions/residential/water-level/index.lv.jsx" /* webpackChunkName: "component---src-pages-solutions-residential-water-level-index-lv-jsx" */)
}

